import React, { useEffect, useState } from 'react';
import {  Link } from "react-router-dom";
import './css/Galeria.css'
import imgbebe from './../../Assets/roupasbebe.png'
import imgcasal from './../../Assets/casal.jpg'
import ClipLoader from "react-spinners/MoonLoader"
import {css} from "@emotion/core"
import axios from "axios"

const  Galeria = ()=>{ 
    const [wpPost, setWpPost] = useState([])
    const [loading, setLoading] = useState(true)

    
    useEffect(( )=>{
        getApi()
    },[1])

    const getApi = async ()=>{
        
        const {data} = await axios.get("https://drops.lup.com.br/wp-json/wp/v2/posts");
        let links = []
        let posts = []
        setLoading(true)

        data.forEach(  (value,i)=>{
          
            if(i <4){
            
                
                const link = value._links["wp:featuredmedia"][0].href
                links.push(link)               

                const post = {
                    description: value.excerpt.rendered,
                    title: value.title.rendered,
                    urlPost: value.link,
                    image: ""
                }

                posts.push(post)
               
            }

        })

        for(let i = 0 ; i<links.length; i++){
            const response = await axios.get(links[i])
            posts[i].image = response.data.guid.rendered
        }
         setWpPost(posts)
         setLoading(false)


    }


    const center = css`
      
        /* position:absolute;
        top: 50%;
        left: 50%; */
        
    `

    return(
    <>
    <section className="galeria">
        <div className="galeria-body">
             <Link to={`/filter/&&7&`}> 
                <div className="buffet" >
                    <h1>Buffet</h1>
                </div>
            </Link>

           <Link to={`/filter/&&26&`}> 
            <div className="casamento">
                    <h1>Cerimonialista</h1>
                </div>
            </Link>

            <Link to={`/filter/&&3&`}> 
                <div className="restaurante">
                    <h1>Restaurante</h1>
                </div>
            </Link>

            <Link to={`/filter/&&17&`}> 
                <div className="foto">
                    <h1>Foto e Vídeo</h1>
                </div>
            </Link>

            <Link to={`/filter/&&13&`}>    
                <div className="decoracao">
                    <h1>Decoração</h1>
                </div>
            </Link>

            <Link to={`/filter/&&25&`}> 
                <div className="vestuario">
                    <h1>Vestuário</h1>
                </div>
            </Link>
        </div>
</section>

        
        
        {/* AQUI FICA O LOADING */}

    {/* <section className="galeria-posts container-fluid">
        <div className="row loading-row">
      
        {
            !loading?
            
            wpPost.map((post, i)=>(
                
                    i===0?   
                        <div className="col-lg-6 post-big">
                        <h1 dangerouslySetInnerHTML={{__html:post.title}}></h1>
                        <h2 dangerouslySetInnerHTML={{__html:post.description}}></h2>
                        <div>
                            <img className="img-fluid" src={post.image} alt="imagem"/>
                        </div>
                    </div>: 
                        i===1?
                    <div className="col-lg-6 post-body">
                        {
                            wpPost.map((miniPost,i)=>(

                                i>=1?
                                <div className="row mini-post">
                                <div className="col-lg-12">
                                    <div className="col-lg-12 post-body">
                                        <div className="row">
                                            <div className="col-md-4">

                                                <img className="img-fluid" src={miniPost.image} alt="imagem"/>

                                            </div>

                                            <div className="col-lg-6">
                                            <p dangerouslySetInnerHTML={{__html:miniPost.description }}/>
                                            </div>
                                      

                                        </div>
                                       
                                    </div>
                            
                                </div>
                                </div> :""



                            ))
                       
                        }
                     
                    </div>:""


               
            )):
            <ClipLoader css={center} size={40} color={"#424242"} loading={loading} />

                  
          
         
        }
    </div>
      
    </section>     */}
    </>
    )

}

export default Galeria;