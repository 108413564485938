import React, {useEffect, useState} from "react"
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Footer from "../../components/Footer/Footer"
import api from "../../Services/api"
import AutoCompleteMusica from "./components/AutoCompleteMusica"
import AutoCompleteCulinaria from "./components/AutoCompleteCulinaria"
import ModalAlert from "../../components/modal"
import "bootstrap/dist/css/bootstrap.css"
import "./style.css"


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    
      
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div className="box-tables">{children}</div>
          </Box>
        )}
      </div>
    );
  }
  

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }
    function CadastroConsumidor() {

    const [value, setValue] = React.useState(0);
    const [imagens, setImagens] = useState(null)
    const [file, setFile] = useState(null)
    const [nome, setNome] = useState("")
    const [sobrenome, setSobrenome] = useState("")
    const [sexo, setSexo] = useState("")
    const [dataNascimento, setDataNascimento] = useState("")
    const [estado, setEstado] = useState([])
    const [cidade, setCidade] = useState([])
    const [musica, setMusica] = useState([])
    const [culinaria, setCulinaria] = useState([])
    const [show, setShow] = useState(false)
    const [musicasId, setMusicasId] = useState([])
    const [culinariaId, setCulinariaId] = useState([])
    const [token, setToken] = useState("")
    const [email, setEmail] = useState("")
    const [senha, setSenha] = useState("")
    const [modalMessage, setModalMessage] = useState("")
    const [btnMessage, setBtnMessage] = useState("")



    const getMusicID = ()=>{
        let  dados = document.querySelectorAll(".MuiChip-label")
        let array =[]
        let musica_id =[]

        for(let i=0; dados.length>i;i++){
           array.push(dados[i].firstChild.nodeValue)
        }

        for(let j=0; array.length > j; j++){
            let nome = array[j]
            for(let k=0; musica.length > k; k++){
                if(musica[k].nome == nome){
                    musica_id.push(musica[k].id)
                }
            }
        }

        setMusicasId(musica_id)
        
        
      }

      const getCulinariaID = ()=>{
        let  dados = document.querySelectorAll(".culinaria-chip .MuiChip-label")
        let array =[]
        let culinaria_id =[]

        dados.forEach(dado=>{
            array.push(dado.firstChild.nodeValue)
        })


        for(let j=0; array.length > j; j++){
            let descricao = array[j]
            for(let k=0; culinaria.length > k; k++){
                if(culinaria[k].descricao == descricao){
                    culinaria_id.push(culinaria[k].id)
                }
            }
        }

      
        setCulinariaId(culinaria_id)
        
      }


    
      const loadMusic = async ()=>{
        const response = await api.get("/estilomusical")
        setMusica(response.data)
       
      }
      

      
  const loadCulinaria = async ()=>{
    const response = await api.get("/opcoesculinarias")
    setCulinaria(response.data)
    
}


    useEffect(()=>{
        loadEstado()
        loadMusic()
        loadCulinaria()
        
    },[])

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const HandleChangeImg = (e)=>{
        setFile(URL.createObjectURL(e.target.files[0]))
    }

    const loadEstado = async ()=>{

        const response = await api.get("/estados")
        setEstado(response.data)
        
    }

    const handleSelect = async (e) =>{
        let id = e.target.value
        async function loadCidades(){
            const response = await api.get(`/cidades/${id}`);
            
            setCidade(response.data);

            
        }
        loadCidades()

    }

    const onChangeDefaultFile = (e)=>{
        if((e.target.files[0].size/1024)<=150){

            setImagens(e.target.files[0])
            HandleChangeImg(e)

        }else{
           setModalMessage("Selecione uma imagem com no máximo 150kb")
           setBtnMessage("Fechar")
           setShow(true)
        }
    
    } 

    const handleClose = () => setShow(false);


    const enviaInformacoes = async (e)=>{

            e.preventDefault()

            let citId = document.querySelector("#selectCidade").value
            let estId = document.querySelector("#selectEstado").value

            let form_data = new FormData()
            form_data.append("name",nome)
            form_data.append("lastname", sobrenome)
            form_data.append("sexo", sexo)
            form_data.append("dtnascimento",dataNascimento)
            form_data.append("cidade_id",citId)
            form_data.append("estado_id", estId)
            form_data.append("fotodestaquecaminho", imagens)
         
        
        

    
        try {
            const response = await api.post("/consumidor/cadastrar",form_data)
            // console.log(response)

            setToken(response.data.token)

            let next = document.querySelector("#scrollable-force-tab-1")

            next.click()
        } catch (error) {
                // console.log(error)
        }
      
    }

    const enviaPreferencias = async (e)=>{
        e.preventDefault()
       
        let sairId = []
        let sairchekboxes = document.querySelectorAll("input[type=checkbox]:checked")

        sairchekboxes.forEach(chek=>{
            sairId.push(chek.value)
        })


        let dados ={
            idmusica: musicasId,
            idculinaria: culinariaId,
            idprefsaida:sairId
        }

       
        try {
            const response = await api.post("/consumidor/preferencia",dados, {
                headers:{
                    "Accept":"application/json",
                    "Content-Type":"application/x-www-form-urlencoded",
                    "Authorization": `Bearer ${token}`
                }
            })

            let next = document.querySelector("#scrollable-force-tab-2")
            next.click()
            // console.log(response)
                   
        } catch (error) {
            // console.log(error)
        }

    }


    const enviaSenha =  async (e)=>{

        e.preventDefault()

        let dados = {
            email: email,
            password: senha
        }

        // console.log(token)

    
        try {
            const response = await api.put("/consumidor/salvar/consumidor",dados, {
                headers:{
                    "Accept":"application/json",
                    "Content-Type":"application/json",
                    "Authorization": `Bearer ${token}`
                }
            })

            setModalMessage("Consumidor cadastrado com sucesso")
            setBtnMessage("Finalizar")
            setShow(true)
        } catch (error) {
            // console.log(error)
            setBtnMessage("Fechar")
            setModalMessage("Erro ao cadastrar")
            setShow(true)
        }
    }


    
  
  
    return (
      <div className="container-fluid">
          <div className="row">
                <div className="col-md-10 mt-30 offset-md-1">
                    <h1 className="text-center">Cadastrar Consumidor</h1>
                    <AppBar className="cad-anunciante" position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="on"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Informações" {...a11yProps(0)} />
                        <Tab label="Preferências" {...a11yProps(1)} />
                        <Tab label="Senha" {...a11yProps(2)} />
                    </Tabs>
                    </AppBar>
                    <TabPanel className="cad-anunciante-body" value={value} index={0}>

                    <form onSubmit={enviaInformacoes}>
                    <div className="row">

                        <div className="form-group col-md-6 col-lg-4">

                            <input required onChange={(e)=> setNome(e.target.value)} type="text" className="form-control"  placeholder="Nome"/>


                        </div>

                        <div className="form-group col-md-6 col-lg-4">

                            <input required onChange={(e)=> setSobrenome(e.target.value)} type="text" className="form-control"  placeholder="Sobrenome"/>

                        </div>

                    </div>

                    <div className="row">

                
                            <div className="form-group col-md-4 col-lg-2 ">

                                <p>Sexo</p>

                                <div className="form-check form-check-inline">
                                    <input required onChange={(e)=> setSexo(e.target.value)}  className="form-check-input" type="radio" name="sexo" id="m" value="m"/>
                                    <label className="form-check-label" htmlFor="m">M</label>

                                </div>

                                <div className="form-check form-check-inline">

                                    <input required onChange={(e)=> setSexo(e.target.value)} className="form-check-input" type="radio" name="sexo" id="f" value="f"/>
                                    <label className="form-check-label" htmlFor="f">F</label>

                                </div>

                            </div>

                            <div className="form-group col-md-4 col-lg-3">
                            <label className="form-check-label" >Data de nascimento</label>

                                <input required onChange={(e)=> setDataNascimento(e.target.value)} type="date" className="form-control"  placeholder="Data de nascimento"/>

                            </div>

                    </div>

                    
                <div className="row">

                    <div className="col-md-4 col-lg-3">

                        <div className="form-group">
                            <select required onChange={handleSelect} name="" id="selectEstado" className="form-control">
                                <option value="">Estado</option>

                                {
                                    estado.map(es =>(

                                        <option key={es.id} value={es.id}>{es.nome} </option>

                                    ))
                                }
                                
                               
                            </select>
                        </div>

                    </div>


                    <div className="col-md-4 col-lg-3">

                        <div className="form-group">
                            <select required name="" id="selectCidade" className="form-control">
                                <option value="">Cidade</option>
                                {
                                    cidade.map(ci =>(
                                        <option key={ci.id} value={ci.id}>{ci.nome} </option>
                                    ))
                                }
                            </select>
                        </div>

                    </div>


                </div>


                <div className="row">

                    <div className="col-md-3">

                        <div className="button-wrapper">
                            <span className="label">
                                Escolher avatar
                            </span>
        
                            <input required type="file" onChange={(e)=> onChangeDefaultFile(e)} name="upload" id="upload" className="upload-box" placeholder="Upload File"/>
        
                        </div>

                    </div>

                <div className="col-md-2">
                    <img className="avatar-img" src={file} alt=""/>
                </div>

                </div>   

                <div className="row">

                    <div className="col-md-3 mt-60">
                        <button type="submit" className="btn form-control btn-save">Salvar</button>
                    </div>
                   
                </div>  
                </form>   
                    </TabPanel>

            <TabPanel className="cad-anunciante-body" value={value} index={1}>
                <form onSubmit={enviaPreferencias}>
                     <div className="row">

                        <div className="col-md-6">
                            <label htmlFor="">Estilo músical</label>
                            <div className="form-group">
                                <AutoCompleteMusica />
                            </div>

                        </div>

                    </div> 


                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="">Opção de culinária: (Marcar até 3) </label>
                            <div className="form-group">
                               <AutoCompleteCulinaria/>
                            </div>

                        </div>

                    </div> 


                    <div className="row">

                        <div className="col-md-6">

                        <label htmlFor="">Para sair com seus amigos, você prefere:</label>
                             <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="1" />
                                <label className="form-check-label">
                                   Barzinho
                                </label>
                            </div>

                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="2" />
                                <label className="form-check-label">
                                   Balada
                                </label>
                            </div>

                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="3" />
                                <label className="form-check-label">
                                   Esquenta+Balada
                                </label>
                            </div>

                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="4" />
                                <label className="form-check-label">
                                   Restaurante para bater um papo
                                </label>
                            </div>

                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="5" />
                                <label className="form-check-label">
                                    Encontro na casa de alguém (dai não preciso lavar a louça)
                                </label>
                            </div>
                        </div>

                    </div>

                    <div className="row">

                        <div className="col-md-3 mt-60">
                            <button type="submit" onClick={()=> {getCulinariaID(); getMusicID()}}  className="btn form-control btn-save">Salvar</button>
                        </div>

                    </div> 

                    </form>

                    </TabPanel>

                    <TabPanel className="cad-anunciante-body" value={value} index={2}>
                       <form onSubmit={enviaSenha}>
                       
                        <div className="row">
                            <div className="form-group col-md-6 col-lg-3">

                                <input onChange={(e)=>setEmail(e.target.value)} required type="email" className="form-control"  placeholder="Email"/>

                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-6 col-lg-3">

                                <input  onChange={(e)=>setSenha(e.target.value)} required type="password" className="form-control"  placeholder="Senha"/>

                            </div>
                        </div>

                    <div className="row">

                        <div className="col-md-4 col-lg-3 mt-30">
                            <button type="submit"  className="btn form-control btn-save">Salvar</button>
                        </div>

                    </div> 

                    </form>
                    </TabPanel>
           </div>
           <Footer/>
           <ModalAlert title="" body={modalMessage} btnMessage={btnMessage} open={show} close={handleClose} />
        </div>
      
      </div>
    );
  }  

export default CadastroConsumidor;