import React,{useState} from "react"
import Modal from 'react-bootstrap/Modal'
import Image from "../../../../Services/img"
import "./style.css"


const ModalEmail = ({show, setShow})=>{
    const handleClose = () => setShow(false);
    
      return (
        <>
  
        <Modal className="modal-convites" show={show} onHide={handleClose}>

          <Modal.Header closeButton>

            <Modal.Title></Modal.Title>

          </Modal.Header>

                <Modal.Body>
                    
                    

                        <div className="row">
                            <div className="col-md-12 text-center">

                             <p>Convites enviados com sucesso!</p>

                            </div>
                        </div>
                

                </Modal.Body>
{/* 
          <Modal.Footer>

            <button variant="primary" className="btn btn-modal" onClick={handleClose}>
              Fechar
            </button>

          </Modal.Footer> */}

        </Modal>
      </>
      )

}

export default ModalEmail;