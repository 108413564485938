import React from "react";
import DefaultUser from '../../../../Assets/defaultuser.jpeg';
import { Check } from "../Check";
import { Open } from "../Open";
import "./style.css";

export function SupplierCard({ supplier, onClick, handleAbout, checked }) {
  return (
    <div className="supplier-card">
      <div className="supplier-card__header">
        <Open
          width={16}
          height={16}
          color={"#c6c6c6"}
          onClick={() => handleAbout( supplier.servico_id, supplier.anunciante_id )}
        />

        <Check
          width={20}
          height={20}
          color={checked ? "#BE3E70" :"#c6c6c6"}
          onClick={() => onClick(supplier.servico_id)}
        />
      </div>

      <div className="supplier-card__icon">
        <img src={supplier.anunciante_foto ?? DefaultUser} alt="logo" />
      </div>

      <div className="supplier-card__name">
        <p>{supplier.anunciante_nome ?? '(sem nome)'}</p>
      </div>

      <div className="supplier-card__label">
        <p>{supplier.servico_nome ?? '(sem serviço)'}</p>
      </div>
    </div>
  );
}
