import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import api from '../../Services/api';
import image from '../../Services/img';
import OrcamentoModal from './components/OrcamentoModal';
import './css/Anuncio.css';
import Tab from './tabs';
// import AlertModal from "./components/AlertModal"
import Footer from '../../components/Footer/Footer';

export default function Anuncio(){

    let params = useParams();
    let history = useHistory();

    const [data, setData] = useState([]);
    const [show, setShow]= useState("")

    const handleShow = () => setShow(true);

    useEffect(()=>{
        fixLoad()
        async function loadExibir(){
            const response = await api.get(`/publico/anunciante/pagina/${params.id}`);
            // console.log("imagem", response.data)
            setData(response.data)
        }

        loadExibir();
        // console.log("paramns",params)
        // console.log("history", history)

    },[params.id])


    const fixLoad = ()=>{


        document.querySelector("html").style="scroll-behavior: unset;"
        setTimeout(()=>{
            window.scrollTo(0, 0);

        },1)

    }


    return(
        <>
        <div className="container-anuncio">
            {/* <div className="btn-anuncio">
                <button onClick={handleShow}>Solicitar orçamento</button>
            </div> */}
            <div className="main-anuncio">
                <div className="imagem-anuncio">
                    <img src={`${image}${data.foto_destaque}`}/>
                </div>
                <h1>{data.fantasia}</h1>
                <p>{data.descricao}</p>

            </div>
            <div>

            </div>
            <div className="container-tabs">
                <Tab/>
            </div>


        </div>
        <OrcamentoModal  show={show} setShow={setShow} id={params.idAnunciante} telefoneOrcamento={data.celular} />
        <Footer/>

        </>
    )
    }
