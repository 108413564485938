import React from "react"
import "./style.css"

const NotFound = ()=>(
   <div className="jumbotron text-center not-found">
       <h1>404</h1>
       <h1>Oops não encontramos esta página...</h1>

   </div>
)


export default NotFound;