import React from 'react';
import './css/banner.css'
import { useHistory } from "react-router-dom";
export default function Banner(){
    let history = useHistory();
    // console.log(history)

 
return(
    <article>
        <div className="banner">
        <div className="banner-container">
            <h1>
                MONTE QUALQUER EVENTO GRATUITAMENTE EM ALGUNS CLIQUES!
            </h1>
            <h2>
                Encontre e contrate profissionais qualificados para qualquer tipo de festa e evento.
            </h2>
           <a href="#filtro"><button >
             Começar agora! 
            </button></a>
        </div>
        </div>

    </article>
)
}