import React,{useEffect, useState} from "react"
import Modal from 'react-bootstrap/Modal'
import { useParams, useHistory } from "react-router-dom";
import InputMask from "react-input-mask"
import api from '../../../Services/api'
import apiWhatsapp from '../../../Services/apiWhatsap'
import moment from "moment"

const OrcamentoModal = ({show, setShow, id, telefoneOrcamento})=>{

  const [nome, setNome] = useState("")
  const [email, setEmail] = useState("")
  const [telefone, setTelefone] = useState("")
  const [dataEvento, setDataEvento] = useState ("")
  const [localizacao, setLocalizacao] = useState("")
  const [descricao, setDescricao] = useState("")
  const [messageModal, setMessageModal] = useState()
  

  let params = useParams();


  const removeCaracter = (param) =>{
    //remove caracteres especiais
    param = param.replace(/[&\/\\#,+()$~%.'":*?<>{}-]/g,'');
    param = param.replace(/[^\d]+/g,"");
    return param
}



  const enviaOrcamento = (e)=>{
     dados()
     setNome("")
     setEmail("")
     setTelefone("")
     setDataEvento("")
     setLocalizacao("")
     setDescricao("")
  }


  const enviaWhatsapp = (e)=>{

    dadosWhatsapp()

  }


  const dados = async ()=>{

    let data={
        nome: nome,
        email:email,
        descricao: descricao,
        localizacao: localizacao,
        datadoevento: removeCaracter(dataEvento) ,
        telefone:removeCaracter (telefone)
    }

    // console.log(data)

    try {
        const response = await api.post(`/orcamento/solicitar/avulso/${params.idAnunciante}`,data)
        // console.log(response)
        setMessageModal(true)
        setTimeout(() => {
            handleClose()
            setMessageModal("limpar")
        }, 3000);
        
        
    } catch (error) {
        // console.log(error)
        setMessageModal(false)
        setTimeout(() => {
            handleClose()
            setMessageModal("limpar")
        }, 3000);


    }

  }



  const dadosWhatsapp = async ()=>{

    let dados={
        nome: nome,
        email:email,
        descricao: descricao,
        localizacao: localizacao,
        datadoevento: moment(dataEvento).format('DD/MM/YYYY'),
        telefone: telefone
    }

    // console.log(dados)
    // console.log(telefoneOrcamento)
    let mensagem = `---> Solicitação enviada a partir do site www.lup.com.br\n
        Nome: ${dados.nome}\n
        E-mail: ${dados.email}\n
        Telefone: ${dados.telefone}\n
        Descrição: ${dados.descricao}\n
        Localização: ${dados.localizacao}\n
        Data do Evento: ${dados.datadoevento}\n
    `
    let url = `${apiWhatsapp}${telefoneOrcamento}'&text=${encodeURI(mensagem)}`
    openWhatsapp(url)
  }


  function openWhatsapp(url) {
    var win = window.open(url, '_blank');
    win.focus();
}


    const handleClose = () => setShow(false);
    
    const alertModal = ()=>{

        if(messageModal==true){
            return (
                <div className="col-md-12 text-center"> 
                    <h1 className="alert-modal">Enviado com sucesso</h1>
                </div>
                
            )
        } else if(messageModal==false){
            return (
                <div className="col-md-12 text-center">
                    <h1 className="alert-modal">Erro ao enviar Orçamento</h1>
                </div>
                
            )
        } else return ("")
    }
      return (
        <>
        <Modal show={show} onHide={handleClose}>

          <Modal.Header closeButton>

            <Modal.Title>Solicitar Orçamento</Modal.Title>

          </Modal.Header>
                <Modal.Body>
                    
                    <div className="container">

                     
                        <div className="row">
                     
                            <div className="col-md-12 text-center">
                            
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="">Nome e sobrenome</label>
                                        <input onChange={(e)=>{setNome(e.target.value)}}  type="text" className="form-control"/>
                                </div> 
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="">E-mail</label>
                                    <input onChange={(e)=>{setEmail(e.target.value)}}  type="text" className="form-control" />
                                </div> 
                            </div>

                            <div className="col-md-12">
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">Telefone</label>
                                            <InputMask onChange={(e)=> setTelefone(e.target.value)} required  type="text" className="form-control" mask="(99) 9999-99999"  placeholder="Telefone"/>
                                        </div> 
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">Data do Evento</label>
                                            <input onChange={(e)=>setDataEvento(e.target.value)} type="date" className="form-control"/>
                                        </div> 
                                    </div>
                                </div>
                               
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                        <label htmlFor="">Localização do Evento</label>
                                        <input onChange={(e)=>{setLocalizacao(e.target.value)}}  type="text" className="form-control" />
                                </div> 
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                        <label htmlFor="">Descreva seu Evento</label>
                                        <textarea onChange={(e)=>{setDescricao(e.target.value)}}  type="text" className="form-control"/>
                                </div> 
                            </div>

                            {
                                
                                alertModal()

                            }
                        </div>

                    </div>


                </Modal.Body>

          <Modal.Footer>

            <button onClick={enviaOrcamento} variant="primary" disabled={nome.length> 0 && email.length >0 && telefone.length >0 && dataEvento.length >0 && localizacao.length >0 && descricao.length > 0  ? false :true} className="btn btn-modal">
              Enviar
            </button>
            
            {

                telefoneOrcamento?
                    <button onClick={enviaWhatsapp} disabled={nome.length> 0 && email.length >0 && telefone.length >0 && dataEvento.length >0 && localizacao.length >0 && descricao.length > 0  ? false :true}  className="btn btn-whats">
                    Whatsapp
                    </button>:""
                

            }
        


          </Modal.Footer>

        </Modal>

       
      </>
      )

}

export default OrcamentoModal;