import React from "react"
import {Link} from "react-router-dom"
import "bootstrap/dist/css/bootstrap.css"
import Footer from "../../components/Footer/Footer"
import "./style.css"


const QuemSomosAnunciante = () =>(
  <div className="container-fluid">
    <div class="row text-justify">
    <section>
        <div class="col-lg-10 offset-lg-1 offset-lg-1 mt-30" >
            <h4 className="text-center">Como funciona – Para Anunciantes</h4>
        </div>

        <div class="col-lg-10 offset-lg-1">
            <p>O Lup é uma plataforma democrática feita especialmente para aproximar você que fornece serviços e produtos para eventos de seus clientes. Através de um sistema simples e prático de utilizar, seus clientes poderão encontrá-lo, pedir seu orçamento e contratá-lo sem burocracias, e tudo isso feito através de uma plataforma transparente e organizada que mantém o registro de perguntas e respostas para que nada seja feito além do combinado.</p>
            <p>Além de tudo isso, não precisa se preocupar com valores. A mensalidade é fixa por anúncio (não ficamos com percentual nenhum da sua venda) e o que define a ordem de exposição é a sua qualidade e profissionalismo (veja ranqueamento). Ou seja, quanto maior a sua pontuação, melhor seu posicionamento na plataforma! Sem contar que você pode delimitar a sua área de atuação, os tipos de eventos e os dias que atende, tudo isso para que o público certo chegue até você.</p>
        </div>

        <div class="col-lg-10 offset-lg-1 offset-lg-1 mt-30" >
            <h5 className="text-center">Sistema de Anúncios</h5>
        </div>

        <div class="col-lg-10 offset-lg-1">
            <p>Para que o seu cliente encontre EXATAMENTE o que você oferece, aqui no LUP os anúncios são separados por serviço. Deste modo você pode ser conhecido por trabalhar com segurança, mas se também presta o serviço de vallet, você pode potencializar a sua visibilidade e prestar atingir o cliente que procura exatamente um ou outro serviço. Em outras palavras, quem já tem a segurança contratada para um evento vai poder achar seu serviço de vallet. Fica muito mais fácil vender assim!</p>
            <p>E para fazer o meu cadastro como anunciante. Como faço?</p>
        </div>

        <div class="col-lg-10 offset-lg-1 offset-lg-1 mt-30" >
            <h5 className="text-center">Cadastro de Anunciante</h5>
        </div>

        <div class="col-lg-10 offset-lg-1">
            <p>Então vamos começar!</p>
            <p>Para ver como fazer parte de tudo isso, siga os passos abaixo:</p>
            <ul className="p-4">
                <li>Acesse a página <a href="www.lup.com.br">www.lup.com.br</a></li>
                <li>No menu superior, clique em cadastro e escolha a opção anunciante;</li>
                <li>Preencha todos os campos corretamente. Lembre-se, utilizaremos estes dados para gerar sua nota fiscal e o e-mail será seu meio de contato conosco;</li>
                <li>Clique em salvar;</li>
                <li>Você receberá um e-mail de confirmação. Apenas siga as instruções para confirmar seus dados;</li>
                <li>Agora você já pode fazer seu login como anunciante!</li>
            </ul>
            <p>Veja a seguir como fazer o cadastro de seu anúncio:</p>
        </div>
    </section>

    <section class="">
        <div class="col-lg-10 offset-lg-1 purple-courtain  mt-30" >
            <h5 className="text-center">Cadastro de Anúncio</h5>
    
            <p>Lembre-se: Sempre no final de cada aba clique em ‘Próximo’ para continuar e salvar as informações inserida.</p>
            <ul className="pl-4">
                <li>Faça o login na sua conta de Anunciante;</li>
                <li>Aceite os termos de serviço;</li>
                <li>Assim que surgir a mensagem de que você não possui anúncio cadastrado, é só clicar em SIM para iniciar o processo. Caso esteja fazendo o segundo ou terceiro anúncio, é só clicar em Novo Anúncio para criar mais um anúncio;</li>
                <li>Você será levado para a aba “Serviço” para configuração do anúncio. Complete todos os dados para que o seu cliente encontre todas as informações necessárias;
                    <ul className="pl-4">
                        <li>(**IMPORTANTE! Faça uma boa descrição do seu negócio. Verifique o português e a clareza do texto. Isso conta muito na hora do consumidor escolher o prestador de serviço**)</li>
                    </ul>
                </li>
                <li>A aba ‘Temas’ é onde você poderá mostrar se seu negócio tem algum tema principal ou não. Também é onde você irá inserir sua abrangência de atendimento. Essa parte é de grande importância, porque você só será visto por clientes dentro da sua área de atuação.
                    <ul  className="pl-4">
                        <li>Ao selecionar a abrangência NACIONAL, você deverá selecionar quais estados atenderá;</li>
                        <li>Ao selecionar a abrangência ESTADUAL, você deverá selecionar quais municípios que você atende dentro do seu estado.</li>
                        <li>Ao selecionar a abrangência LOCAL, entenderemos que você atende apenas o município que está localizada a sua empresa ou sua base de operações.</li>
                    </ul>
                </li>
                <li>A aba promoções está aqui para te ajudar a se destacar. Insira todos os dados e descrição da sua promoção e ela ficará disponível exatamente o tempo que você determinar. Você também pode programar promoções futuras para que elas entrem automaticamente nas buscas quando for a hora certa! Lembre-se de colocar todas as informações importantes na descrição de sua promoção para que não haja nenhum mal-entendido.</li>
                <li>A Aba fotos é de suma importância. É aqui que você vai inserir as imagens que serão mostradas para seus clientes. É a hora de encantá-los! Uma atenção especial vai para a ‘Imagem Destaque’. É ela que será mostrada nas buscas do seu cliente e que mais deve chamar a atenção dele. Geralmente é aqui que são colocados os logotipos da empresa ou serviço.</li>
                <li> aba de vídeos é supersimples de usar. Caso você possua um vídeo em alguma plataforma online, a única coisa que precisa fazer é copiar o ‘código de incorporação’ (ou embed do vídeo) nesta plataforma e colar no espaço reservado para ele. Geralmente é só clicar com o botão direito do mouse sobre o vídeo e selecionar a opção do código de incorporação. Se não for este o caso, verifique com a plataforma como conseguir este código.</li>
                <li>A aba de prêmios é dedicada para que você possa colocar a imagem e descrição de prêmios que você ou sua empresa já conquistaram.</li>
                <li>Clique em Finalizar para terminar o seu anúncio.</li>
            </ul>
        </div>
    </section>

    <section>
        <div class="col-lg-10 offset-lg-1 mt-60">
            <p>Lembre-se que você pode alterar estes dados sempre que necessário.</p>
            <p>Após a finalização, nossa equipe irá fazer a verificação do anúncio e liberá-lo para as buscas. Caso ocorra alguma discrepância com as políticas do site, você será informado por e-mail.</p>
        </div>
    </section>

    <section class="">
        <div class="blue-courtain mt-60 col-lg-10 offset-lg-1 container-pergunta">
            <p class="container-pergunta__pergunta">
                Pergunta: Tenho alguma carência ou estou me comprometendo a contratar mais meses?
            </p>
            <p class="container-pergunta__resposta">
                Resposta: Não. Aqui no Lup não existem armadilhas. Tudo é claro e transparente. Fazendo o seu cadastro você tem disponível os primeiros 60 dias de anúncio gratuito (ou mais, no caso de alguma promoção). Se após este período não quiser continuar, você poderá desativar seu cadastro. Se desejar desativar antes do fim destes meses promocionais, também é possível. Não será cobrado nada a mais do que você solicitou.
            </p>
        </div>
    </section>

    <section>
        <div class="col-lg-10 offset-lg-1 offset-lg-1 mt-30" >
            <h5 className="text-center">Funcionamento das MENSALIDADES</h5>
        </div>

        <div class="col-lg-10 offset-lg-1">
            <p>As mensalidades são cobradas de maneira simples. Cada empresa ou profissional tem um cadastro, e este cadastro pode ter vários anúncios. Cada anúncio tem um valor separado, com desconto para o tempo contratado (até 12 meses).</p>
            <p>Veja a Tabela abaixo para ver como funciona:</p>
        </div>

        <div class="scroll col-lg-6 offset-lg-3 mt-30">
            <table class="mensalidades table ">
                <tr>
                    <th rowspan="9"><span class="vertical">meses</span></th>
                </tr>
                <tr>
                    <th rowspan="2"></th>
                </tr>
                <tr>
                    <th colspan="5"><h4>Anúncios</h4></th>
                </tr>
                <tr>
                    <th></th>
                    <th class="num">1</th>
                    <th class="num">2</th>
                    <th class="num">3</th>
                    <th class="num">4</th>
                    <th class="num">5</th>
                </tr>
                <tr>
                    <th>1</th>
                    <td>R$ 77,90</td>
                    <td>R$ 155,80</td>
                    <td>R$ 233,70</td>
                    <td>R$ 311,60</td>
                    <td>R$ 389,50</td>
                    <td>0%</td>
                </tr>
                <tr>
                    <th>3</th>
                    <td>R$ 70,11</td>
                    <td>R$ 140,22</td>
                    <td>R$ 210,33</td>
                    <td>R$ 280,44</td>
                    <td>R$ 350,55</td>
                    <td>10%</td>
                </tr>
                <tr>
                    <th>6</th>
                    <td>R$ 54,53</td>
                    <td>R$ 109,06</td>
                    <td>R$ 163,59</td>
                    <td>R$ 218,12</td>
                    <td>R$ 272,65</td>
                    <td>30%</td>
                </tr>
                <tr>
                    <th>12</th>
                    <td>R$ 46,74</td>
                    <td>R$ 93,48</td>
                    <td>R$ 140,22</td>
                    <td>R$ 186,96</td>
                    <td>R$ 233,70</td>
                    <td>40%</td>
                </tr>
                <tr>
                    <th></th>
                    <td colspan="5" class="obs"><span>*valores mensais</span></td>
                </tr>
            </table>
        </div>
    </section>

    <section class="">
        <div class="blue-courtain col-lg-10 offset-lg-1 ">
            <p class="container-pergunta__pergunta">
                Pergunta: Como recebo do consumidor?
            </p>
            <p class="mt-15">
                Resposta: O Lup trabalha apenas conectando você com seus clientes. Não recebemos nenhuma comissão e as garantias (se aplicáveis) são fornecidas exclusivamente pelo fornecedor do serviço ou bem. É necessário que o meio de pagamento seja acertado diretamente com o consumidor.
            </p>
        </div>
    </section>

    <section>
        <div class="col-lg-10 offset-lg-1 offset-lg-1 mt-30" >
            <h5 className="text-center">Como Funciona o RANQUEAMENTO</h5>
        </div>
        <div class="col-lg-10 offset-lg-1 mt-15">
            <p>O processo de ranqueamento do LUP foi criado para premiar, acima de tudo, a qualidade de atendimento. Para isso, utilizamos duas variáveis básicas.</p>
            <p className="mt-15"><strong>Média de Qualificação de Cliente</strong> – a pontuação é estabelecida de acordo com o número de estrelas dadas pelo cliente após o fornecimento do serviço;</p>
            <p className="mt-15"><strong>Tempo de resposta de Orçamento</strong> – Quanto mais rápido você responder ao orçamento solicitado pelo seu cliente, mais pontos ganhará;</p>
            <p className="mt-15">Nenhum cliente gosta de ficar esperando por um orçamento, então não é estranho que os fornecedores escolhidos sejam os que respondem mais rápido. A média de pontuação é baseada pela rapidez de atendimento para que você que leva seu trabalho SUPER a sério seja premiado por isso!</p>
            <p className="mt-15">O maior pontuador da categoria, na área de atuação geográfica dele, será o sugerido pela plataforma. Caso o consumidor quiser escolher os fornecedores da listagem para fazer até 5 (cinco) orçamentos, os anunciantes aparecerão por ordem de pontuação.</p>
            <p className="mt-15">Obs.: O tempo de contagem iniciará a partir das 08h00 até as 19h00. Não será somado os horários a partir das 19h00 até as 08h00,</p>
        </div>
    </section>


        <div class="col-lg-10 offset-lg-1 green-courtain offset-lg-1 mt-30">
            <p>Exemplo:</p>
            <p>
                Foi solicitado um orçamento às 18h00.<br />
                O anunciante respondeu enviando o orçamento às 20h00.<br />
                O tempo decorrido para a resposta constará como 1h00,
                pois o tempo das 19h00 até as 20h00 não deve ser considerado.<br />
                Se este mesmo orçamento for respondido às 03h00 da manhã.<br />
                Também será contado apenas 1h00.<br />
                O cronômetro será reiniciado às 08h00 conforme informado.                   
            </p>
        </div>

        <div className="container mb-60">
                <div className="row">
                    <div className="col-lg-12 mt-30">
                      <Link to="./quem-somos"> <button className="btn btn-default">Voltar</button></Link> 
                    </div>
                </div>
            </div>
            <Footer></Footer>

    </div>
    
</div>


)

export default QuemSomosAnunciante