import React from "react";

export function Open({ width, height, color, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={color}
      onClick={onClick}
    >
      <path d="M4.75 3.5C4.06 3.5 3.5 4.06 3.5 4.75V11.25C3.5 11.94 4.06 12.5 4.75 12.5H11.25C11.94 12.5 12.5 11.94 12.5 11.25V9.75C12.5 9.55109 12.579 9.36032 12.7197 9.21967C12.8603 9.07902 13.0511 9 13.25 9C13.4489 9 13.6397 9.07902 13.7803 9.21967C13.921 9.36032 14 9.55109 14 9.75V11.25C14 11.9793 13.7103 12.6788 13.1945 13.1945C12.6788 13.7103 11.9793 14 11.25 14H4.75C4.02065 14 3.32118 13.7103 2.80546 13.1945C2.28973 12.6788 2 11.9793 2 11.25V4.75C2 4.02065 2.28973 3.32118 2.80546 2.80546C3.32118 2.28973 4.02065 2 4.75 2H6.25C6.44891 2 6.63968 2.07902 6.78033 2.21967C6.92098 2.36032 7 2.55109 7 2.75C7 2.94891 6.92098 3.13968 6.78033 3.28033C6.63968 3.42098 6.44891 3.5 6.25 3.5H4.75ZM8 2.75C8 2.55109 8.07902 2.36032 8.21967 2.21967C8.36032 2.07902 8.55109 2 8.75 2H13.25C13.4489 2 13.6397 2.07902 13.7803 2.21967C13.921 2.36032 14 2.55109 14 2.75V7.25C14 7.44891 13.921 7.63968 13.7803 7.78033C13.6397 7.92098 13.4489 8 13.25 8C13.0511 8 12.8603 7.92098 12.7197 7.78033C12.579 7.63968 12.5 7.44891 12.5 7.25V4.561L9.28 7.781C9.21078 7.8526 9.128 7.9097 9.03647 7.94896C8.94495 7.98822 8.84653 8.00886 8.74694 8.00968C8.64736 8.0105 8.54861 7.99148 8.45646 7.95372C8.3643 7.91597 8.28059 7.86024 8.2102 7.78979C8.13982 7.71934 8.08417 7.63557 8.0465 7.54338C8.00883 7.45119 7.9899 7.35242 7.99081 7.25284C7.99173 7.15326 8.01246 7.05485 8.05181 6.96337C8.09116 6.87188 8.14834 6.78915 8.22 6.72L11.44 3.5H8.75C8.55109 3.5 8.36032 3.42098 8.21967 3.28033C8.07902 3.13968 8 2.94891 8 2.75Z" />
    </svg>
  );
}
