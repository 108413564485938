import React, {useEffect, useState} from "react"
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import "bootstrap/dist/css/bootstrap.css"
import Footer from "../../components/Footer/Footer"
import InputMask from "react-input-mask"
import cepApi from "../../Services/cep"
import api from "../../Services/api"
import Modal from "../../components/modal"
import "./style.css"

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
      
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div className="box-tables">{children}</div>
          </Box>
        )}
      </div>
    );

  
  }


const CadastroAnunciante = ()=>{
    const [iscpf, setIscpf] = useState(true)
    const [getCep, setGetCep] = useState([""])

    const [razaoSocial, setRazaoSocial] = useState("")
    const [nomeFantasia, setNomeFantasia] = useState("")
    const [descricao, setDescricao] = useState("")
    const [cpf, setCpf] = useState("")
    const [cnpj, setCnpj] = useState("")
    const [telefone, setTelefone] = useState("")
    const [celular, setCelular] = useState("")
    const [endereco, setEndereco] =useState("")
    const [numero, setNumero]= useState("")
    const [complemento, setComplemento] = useState("")
    const [cep, setCep] = useState("")
    const [bairro, setBairro] = useState("")
    const [estado, setEstado] =useState("")
    const [cidade, setCidade] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState(false)




    const cadastrarAnunciante = async (e) =>{
        e.preventDefault();
       
        let data = {
            razaosocial : razaoSocial,
            fantasia: nomeFantasia,
            descricao: descricao,
            CPF: removeCaracter(cpf),
            CNPJ: removeCaracter(cnpj),
            telefone: removeCaracter(telefone),
            celular: removeCaracter(celular),
            endereco: endereco,
            numero: numero,
            complemento: complemento,
            CEP: removeCaracter(cep),
            bairro: bairro,
            estado_id: estado,
            cidade_id: cidade,
            email: email,
            password: password


        }

         try {
                
            const response = await api.post("/cadastrar/anunciante",data)
            setError(false)
            alert('Anunciante Cadastrado com sucesso! Verificar caixa de e-mail para ativação.')
            window.location.href="https://app.lup.com.br/anunciante/login.html";

         } catch (error) {
           
             setError(true)
         }
    }

    const handleCep = async (e) =>{
          let getCep = e.target.value

          removeCaracter(getCep) 

          const response = await cepApi.get(`${getCep}/json/`)
          setGetCep(response.data)
          handleCidadeEstado()
    
    }

    const handleCidadeEstado = async ()=>{
        let cidadeteste = document.querySelector("#cidade").value
        
        let dados = {
            cidade:cidadeteste
        }
         const response = await api.post("/cidade/nome",dados)
         
         setEstado(response.data[0].idestado)
         setCidade(response.data[0].id)

       

    }

    const removeCaracter = (param) =>{
        //remove caracteres especiais
        param = param.replace(/[&\/\\#,+()$~%.'":*?<>{}-]/g,'');
        param = param.replace(/[^\d]+/g,"");
        return param
    }


return (
    <div className="container-fluid">
        <div className="row">

            <div className="col-md-10 mt-30 offset-md-1">
                <h1 className="text-center">Cadastrar Anunciante</h1>
                <AppBar className="cad-anunciante" position="static" color="default">
                <Tabs
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
                aria-label="scrollable auto tabs"
                >
                <Tab label="Informações"/>
            
                </Tabs>
            </AppBar>

            <TabPanel className="cad-anunciante-body">
              
            <form onSubmit={cadastrarAnunciante}> 
                <div className="form-group col-md-6 p-0">

                    <input onChange={(e)=> setRazaoSocial(e.target.value)} required type="text" className="form-control"  placeholder="Razão Social"/>

                </div>

                <div className="form-group col-md-6 p-0">

                    <input onChange={(e)=> setNomeFantasia(e.target.value)} required type="text" className="form-control"  placeholder="Nome Fantasia"/>

                </div>

                <div className="form-group col-md-6 p-0">

                    <input onChange={(e)=> setDescricao(e.target.value)} required type="text" className="form-control"  placeholder="Descrição"/>

                </div>

                <div className="form-group col-md-4 p-0 d-flex">  

                    <div className="form-check form-check-inline">
                        <input onClick={()=>setIscpf(true)} className="form-check-input" type="radio" checked={iscpf} name="inlineRadioOptions" id="cpf-radio" value="option1"/>
                        <label className="form-check-label" htmlFor="cpf-radio">CPF</label>
                    
                    </div>

                    <div className="form-check form-check-inline">

                        <input onClick={()=>setIscpf(false)} className="form-check-input" type="radio" name="inlineRadioOptions" id="cnpj-radio" value="option1"/>
                        <label className="form-check-label" htmlFor="cnpj-radio">CNPJ</label>
                    
                    </div>
                    {
                        iscpf?
                        <InputMask onChange={(e)=> setCpf(e.target.value)} required type="text" className="form-control" mask="999.999.999-99"  placeholder="CPF"/>:
                        <InputMask onChange={(e)=> setCnpj(e.target.value)} required type="text" className="form-control" mask="99.999.999/9999-99" placeholder="CNPJ"/>
                    }

                    
                </div>

                <div className="row">

                    <div className="form-group col-md-4">

                        <InputMask onChange={(e)=> setTelefone(e.target.value)} required  type="text" className="form-control" mask="(99) 9999-99999"  placeholder="Telefone"/>


                    </div>

                    <div className="form-group col-md-4">

                        <InputMask onChange={(e)=> setCelular(e.target.value)} required type="text" className="form-control" mask="(99) 9999-99999"  placeholder="Celular"/>

                    </div>

                </div>

                <div className="form-group col-md-6 p-0 ">

                    <p>Deseja receber solicitações via whatsapp</p>

                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="pessoa" id="whats-sim" value="option2"/>
                        <label className="form-check-label" htmlFor="whats-sim">Sim</label>

                    </div>

                    <div className="form-check form-check-inline">

                        <input className="form-check-input" type="radio" name="pessoa" id="whats-nao" value="option2"/>
                        <label className="form-check-label" htmlFor="whats-nao">Não</label>

                    </div>

                </div>

                <div className="row">

                    <div className="form-group col-md-8">

                        <input onChange={(e)=> setEndereco(e.target.value)} required type="text" className="form-control"  placeholder="Endereço"/>

                    </div>

                    <div className="form-group col-md-2">

                        <input onChange={(e)=> setNumero(e.target.value)} required type="number" className="form-control"  placeholder="Número"/>

                    </div>


                </div>


                <div className="row">

                    <div className="form-group col-md-4">

                        <input onChange={(e)=> setComplemento(e.target.value)} type="text" className="form-control"  placeholder="Complemento"/>

                    </div>

                    <div className="form-group col-md-4">

                        <InputMask onChange={(e)=> setCep(e.target.value)} required onBlur={handleCep} type="text" className="form-control" mask="99999-999" placeholder="CEP"/>

                    </div>

                    <div className="form-group col-md-4">

                        <input onChange={(e)=> setBairro(e.target.value)} type="text" className="form-control"  placeholder="Bairro"/>

                    </div>



                </div>


                <div className="row">

                    <div className="col-md-3">

                        <div className="form-group">
                            <select  className="form-control">
                                    {
                                        getCep == ""?
                                        <option>Estado</option>:
                                        <option value={getCep.uf}>{getCep.uf}</option> 
                                    }
                                                  
                                
                            </select>
                        </div>

                    </div>

                    
                    <div className="col-md-3">

                        <div className="form-group">
                            <select   name="" id="cidade" className="form-control">

                                {
                                    getCep == ""?
                                    <option >Cidade</option>:
                                    <option  value={getCep.localidade}>{getCep.localidade} </option>
                                }
                            </select>
                        </div>

                    </div>


                </div>

                <div className="row">

                    <div className="form-group col-md-6">
                    
                        <input onChange={(e)=> setEmail(e.target.value)} required type="email" className="form-control"  placeholder="E-mail"/>
                        
                        {
                            error === true ?
                            <p className="error">O Email já está em uso , escolha outro ou recupere sua senha</p> : ""
                            
                        }
                    </div>

                    <div className="form-group col-md-3">
                   
                         <input onChange={(e)=> setPassword(e.target.value)} required type="password" className="form-control"  placeholder="Senha"/>

                     </div>

                </div>

                <div className="row">

                    <div className="col-md-3">
                        <button type="submit"  className="btn form-control btn-save">Salvar</button>
                    </div>
                   
                </div>

               </form> 

                
              
            

            </TabPanel>

            </div>
    

           
   
            <Footer/>

            <Modal />
        </div>

  
    </div>
)
}

export default CadastroAnunciante;
