import React from "react"
import { Link } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.css"
import Footer from "../../components/Footer/Footer"
import "./style.css"


const QuemSomosConsumidor = ()=>(
    <div className="container-fluid">
<div class="row text-justify">
                <section>
                    <div class="col-lg-10 offset-lg-1 offset-lg-1 mt-30">
                        <h4 className="text-center">Como funciona – Para Consumidores (Organizadores)</h4>
                    </div>

                    <div class="col-lg-10 offset-lg-1 text-justify">
                        <p>Ouvi dizer que você está procurando um lugar que te ajude montar o seu evento, não cobre absolutamente NADA para isso e ainda te coloque em contato com os melhores fornecedores da sua região? É isso mesmo que você terá aqui. Basta apenas seguir alguns passos para que todas estas facilidades caiam em seu colo.</p>
                        <p>Faça o seu cadastro – é importante que todos os campos estejam preenchidos para que possamos te ajudar cada vez mais;</p>
                        <p>Escolha o tipo de evento que deseja montar – casamento, aniversário, feiras, reuniões e muitas outras opções! Mas se quiser criar algo totalmente novo (uma festa de boas vindas para alienígenas talvez), você pode montar tudo do início. Só que infelizmente não sabemos o que marcianos comem, ainda...</p>
                        <p>A partir daí é só ir preenchendo o cadastro com os dados do evento como local, horário, descrição etc.  Quando você clicar em próximo, as informações serão salvas e você irá para a próxima aba para colocar mais dados.</p>
                        <p>E não se preocupe. Você poderá voltar depois e alterar algum detalhe que seja necessário.</p>
                    </div>
                </section>

                <section class="">
                    <div class="col-lg-10 offset-lg-1 purple-courtain mt-30">
                        <h5 className="text-center ">Como escolher fornecedores e pedir orçamentos.</h5>
    
                        <p>Na aba de serviços você selecionará o tipo de serviços que serão necessários para seu evento. Após feita esta seleção, na aba ‘Fornecedores’ você poderá escolher até cinco fornecedores por serviço para pedir seu orçamento. Clicando em cada fornecedor, uma aba abrirá para que você possa verificar melhor o serviço oferecido. Depois disso você pode selecionar os fornecedores que mais lhe agradaram e pedir o mesmo orçamento para todos eles.</p>

                        <h5 className="text-center  mt-30">Aprovando e reprovando orçamentos.</h5>

                        <p>Depois de pedir alguns orçamentos você pode receber algumas perguntas dos fornecedores. Isso é normal sendo que eles querem entender exatamente o que você precisa para te atender melhor. Depois disso eles te enviarão o orçamento solicitado e você poderá ver qual que fica melhor no seu orçamento.  Depois é só aprovar o que mais lhe agradou. Todos os orçamentos aprovados irão para a sua calculadora de custos, para te ajudar a manter o registro de tudo que está ocorrendo na organização do evento.</p>
                        <p>IMPORTANTE: Ao aceitar um orçamento, lembre-se que você está aceitando os termos e condições de fornecimento do serviço. É uma confirmação de negócio. Caso tenha alguma dúvida, pergunte antes ao fornecedor.</p>

                        <h5 className="text-center  mt-30">Como pago o fornecedor?</h5>

                        <p>O Lup trabalha apenas conectando os fornecedores com você. Não recebemos nenhuma comissão e as garantias (se aplicáveis) são fornecidas exclusivamente pelo contratado. É necessário que o consumidor acerte com o fornecedor o meio de pagamento.</p>
                    </div>
                </section>

                <section>
                    <div class="col-lg-10 offset-lg-1 mt-60">
                        <h5 className="text-center">Como mandar convites</h5>
                    </div>

                    <div class="col-lg-10 offset-lg-1">
                        <p>Na área de ‘convidados’ você poderá listar quem deseja convidar com seus respectivos e-mails. Após terminar a lista, clique em ‘Montar Convite’, insira a imagem desejada e demais dados. Se quiser deixar para mandar depois, é só clicar em ‘Salvar’. Mas se já quiser enviar, é só clicar em ‘Enviar Convite’ para convidar toda a lista de convidados de uma só vez!</p>
                    </div>
                </section>



             <section>
                    <div class="col-lg-10 offset-lg-1 mt-60 " >
                        <h4 className="text-center">Como funciona – Para Convidados</h4>
                    </div>

                    <div class="col-lg-10 offset-lg-1" >
                        <p>Então quer dizer que te convidaram para um evento? Ser especial é assim mesmo! E ainda mais que quem te convidou tomou o cuidado de fazer o evento aqui no Lup, ou seja, quer que tudo saia da melhor maneira possível para que você se divirta muito! Dá até uma invejinha... </p>
                        <p>Mas agora vamos falar de como as coisas funcionam. Você deve ter recebido um e-mail com o convite, certo? Então é apenas clicar no link (botão) e confirmar sua presença! Se for um evento particular, você receberá uma senha para acesso à página do casamento, aniversário, happy hour, o que quer que seja. Se o evento for público, você receberá no seu email o link para a página dele, ou pode apenas procurar por ele na página inicial. Você que escolhe!</p>
                    </div>
                </section>
              
                    <div class="col-lg-10 offset-lg-1 purple-courtain mt-60">
                         <h5  className="text-center">Mas, e se eu não recebi nenhum e-mail e quero ver se fui convidado para algo?</h5>
                            <p>Boa pergunta! Temos uma área para que você insira seu endereço de e-mail e veja se tem algum convite pendente! Basta fazer o seguinte:</p>
                            <ul className="pl-5">
                                <li>Acessar <a href="www.lup.com.br">www.lup.com.br</a></li>
                                <li>No menu inicial, clicar em Consultar - Convite;</li>
                                <li>No campo disponível colocar o seu e-mail e clicar em pesquisar.</li>
                            </ul>
                            <p>E pronto! O sistema procura por qualquer convite que tenha o seu endereço de e-mail cadastrado.</p>
                      
                    </div>
          



              

            <div className="container mb-60">
                <div className="row">
                    <div className="col-lg-12 mt-30">
                      <Link to="./quem-somos"> <button className="btn btn-default">Voltar</button></Link> 
                    </div>
                </div>
            </div>
                <Footer></Footer>
             </div>
            
             </div>
             
)

export default QuemSomosConsumidor;