import React from "react";

export function Check({ width, height, color, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill={color}
      onClick={onClick}
    >
      <g clip-path="url(#clip0_466_385)">
        <path
          d="M5.54829 8.83638L3.25 6.53755L4.01592 5.77163L5.54829 7.30347L8.61196 4.23926L9.37842 5.00572L5.54829 8.8353V8.83638Z"

        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.541016 6.49984C0.541016 3.20921 3.20872 0.541504 6.49935 0.541504C9.78997 0.541504 12.4577 3.20921 12.4577 6.49984C12.4577 9.79046 9.78997 12.4582 6.49935 12.4582C3.20872 12.4582 0.541016 9.79046 0.541016 6.49984ZM6.49935 11.3748C5.85916 11.3748 5.22523 11.2487 4.63377 11.0038C4.0423 10.7588 3.50489 10.3997 3.0522 9.94698C2.59952 9.4943 2.24043 8.95688 1.99544 8.36542C1.75044 7.77396 1.62435 7.14003 1.62435 6.49984C1.62435 5.85964 1.75044 5.22572 1.99544 4.63426C2.24043 4.04279 2.59952 3.50538 3.0522 3.05269C3.50489 2.60001 4.0423 2.24092 4.63377 1.99592C5.22523 1.75093 5.85916 1.62484 6.49935 1.62484C7.79228 1.62484 9.03225 2.13845 9.94649 3.05269C10.8607 3.96693 11.3743 5.20691 11.3743 6.49984C11.3743 7.79277 10.8607 9.03274 9.94649 9.94698C9.03225 10.8612 7.79228 11.3748 6.49935 11.3748Z"

        />
      </g>
      <defs>
        <clipPath id="clip0_466_385">
          <rect width="13" height="13" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
