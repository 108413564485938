import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import api from '../../../Services/api';
import './../css/informacoes.css';
import Slide from './slide'


export default function Informacoes(){
    let params = useParams();

    const [premio, setPremio] = useState([]);
    const [data, setData] = useState([]);
    const [horarios, SetHorarios] = useState(['']);

    
    useEffect(()=>{
        async function loadPremio(){
            const response = await api.get(`/anuncio/premio/${params.id}`);

            setPremio(response.data)
            
            
        }
        loadPremio()
        // console.log(premio)

        async function loadHorarios(){

            const response = await api.get(`/anuncio/horario_atendimento/${params.id}`)

            // console.log(response)

            for( var i=0; response.data.length > i; i++){
                
                let horaInicio = (response.data[i].horario_inicio)
                horaInicio = horaInicio.slice(0,5)
                response.data[i].horario_inicio = horaInicio

                let horaFim = (response.data[i].horario_fim)
                horaFim = horaFim.slice(0,5)
                response.data[i].horario_fim = horaFim

                // console.log(horaFim)
            }
            
            SetHorarios(response.data)

        }
        loadHorarios();

    },[])
    

    useEffect(()=>{

        async function loadExibir(){
            const response = await api.get(`/publico/anunciante/pagina/${params.id}`);
            
            setData(response.data)
            
        }

        loadExibir();
       
    },[])

    const carregaSlider = function carregando(){

        if(premio[0]){
            return(
                <div>
                    <h1>Prêmio</h1>
                    <Slide imagem = {premio}/>  
                </div>
                  
            )  
        }else{
            return
        }


    } 
    

    return(
        <div className="informacoes-body">
            
            <div>
                <h1>Serviços</h1>
                <p> {data.servico} </p>
            </div>
            <div>
                <h1>Contato/Telefone</h1>
                <p> {data.telefone} </p>
            </div>

            <div>
                <h1>Horários</h1>

                {horarios.map(horario => (
                        
                           <div>
                                  <p>{horario.dia_inicio } à {horario.dia_fim} {horario.horario_inicio} até {horario.horario_fim}</p> 
                           </div>
                         
                           ))
                }
                
            </div>
        

           {carregaSlider()}

        </div>
       
    )
    }