import React, {useEffect, useState} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';

import api from "../../../../Services/api"
import "./style.css"

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));




export default function LimitTags() {

  useEffect(()=>{
    loadMusic()
    // console.log(musica)
},[])

const loadMusic = async ()=>{
  const response = await api.get("/estilomusical")
  setMusica(response.data)
  // console.log(response.data)
}

const [musica, setMusica] = useState([])

   return (
    <div className="">
      <Autocomplete
        multiple
        id="tags-standard"
        options={musica}
        getOptionLabel={(option) => option.nome}
        renderInput={(params) => (
          <TextField 
            {...params}
            variant="standard"
          
         
          />
        )}
      />
      </div>
  );
}



