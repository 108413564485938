import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import "../src/App.css";
import Header from "./components/header/Header";
import Routes from "./routes";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
})

function App() {
  return (
    <ThemeProvider theme={theme} >
      <BrowserRouter>
        <Header />
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
