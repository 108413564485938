import React, {useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import * as moment from "moment"
import "moment/locale/pt-br"
import api from '../../Services/api'
import "bootstrap/dist/css/bootstrap.css"
import Slider from "./components/Slider"
import BootstrapModal from "./components/modal/index"
import FornecedorModal from "./components/fornecedorModal/index.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faStar } from '@fortawesome/free-regular-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import Footer from "../../components/Footer/Footer"
import './css/index.css';

export default function Eventos(){

    const [param, setParam] = useState([])
    const [data, setData] = useState ([])
    const [title, setTitle] = useState("")
    const [descricao, setDescricao] = useState("")
    const [dia, setDia] = useState("");
    const [hora, setHora] = useState("")
    const [endereco, setEndereco] = useState("")
    const [bairro, setBairro] = useState("")
    const [cidade, setCidade] = useState([])
    const [estado, setEstado] = useState([])
    const [show, setShow] = useState(false);
    const [fornecedores, setFornecedores] = useState([])
    const [showFornecedor, setShowFornecedor] = useState(false)
    const handleShow = () => setShow(true);
    const fornecedor = () => setShowFornecedor(true)
    const [images, setImages] = useState([]); 
    const {id} = useParams();
    const [recados, setRecados] = useState([])
    const [recado, setRecado] = useState("")
    const [dataRecado, setDataRecado] = useState("")
    const [nomeRecado, setNomeRecado] = useState("")

    moment.locale("pt-br");

    useEffect(()=>{
        
   
        loadEventos()
        loadRecados()
        

    },[])


    const loadEventos = async ()=>{
        const response = await api.get(`eventos/pagina/${id}`);
        
        setImages(response.data.galeria_imagens)
        setTitle(response.data.nome)
        setDescricao(response.data.descricao)
        setDia(response.data.datadoevento)
        setHora(response.data.horadoevento)
        setEndereco(response.data.endereco)
        setBairro(response.data.bairro)
        setCidade(response.data.cidade.nome)
        setEstado(response.data.cidade.idestado)
        setFornecedores(response.data.fornecedores)
     
       
      
        
        
    }

    const loadRecados = async ()=>{

        const response = await api.get(`consumidor/evento/recados/listar/${id} `)
        setRecados(response.data.data)
        

    }


return(
   <div>
  
           <div className="container-fluid">

                    <div className="row">
                        <div className="col-md-12 mt-30">
                            <h1 className="text-center">{title}</h1>
                        </div>
                    </div>

                    <div className="row mt-30">
                        <div className="col-md-6 col-lg-3 offset-lg-2 info ">

                            <div className="col-md-12">
                               
                                <div className="info-event">
                                    <FontAwesomeIcon className="info-icons" icon={faStar}/>
                                    <p>{descricao} </p>
                                </div>
                            
                            </div>

                            <div className="col-md-12">
                               
                                <div className="info-event">
                                    <FontAwesomeIcon className="info-icons" icon={faCalendar}/>
                                    <p>Dia { moment(dia).format('L')} </p>
                                    <p>Às {hora.substring(0,5)} </p>
                                </div>

                            </div>

                            <div className="col-md-12">
                                
                                <div className="info-event">
                                    <FontAwesomeIcon className="info-icons" icon={faMapMarkerAlt} />
                                    <p> {endereco} </p>
                                    <p> {bairro} </p>
                                    <p> {cidade} / {estado} </p>
                                  
                                </div>

                            </div>

                            <div className="col-md-12">
 
                                <div className="info-event button-link">
                                    <button onClick={fornecedor} className="btn">Confira aqui os profissionais que foram contratados para esta festa</button>
                                </div>

                            </div>

                        </div>


                        <div className="col-md-6 col-lg-4">

                                <Slider urlImage={images} />

                        </div>
                    </div>
    
                    <div className="row">
                    
                        <div className="col-md-6 col-lg-6  offset-lg-2 recados">

                               <h2>Recados</h2> 
                               <button className="btn enviar-recado" onClick={handleShow} >Enviar recado</button>

                        </div>

                    </div>


                    <div className="row">

                    <div className="col-md-6 col-lg-6 offset-lg-2 lista-recados">
                   
                   {
                       recados.map(rec =>(

                            <ul key={rec.id}>
                                <li > <FontAwesomeIcon className="" icon={faCircle}/> {rec.recado} - {rec.nome_remetente} - {moment(rec.created_at).format('L')} </li>
                            </ul>

                       )
     
                       )
                   }
                         
                       
                          
                    
                       

                    </div>

                    </div>

           </div>

           <Footer/>
       <BootstrapModal show={show} setShow={setShow} id={id} />

       <FornecedorModal fornecedores={fornecedores} show={showFornecedor} setShow={setShowFornecedor}/>

   </div>
)
}