import React,{useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import './css/tabs.css';
import Informacoes from './components/Informacoes';
import Promocoes from './components/Promocoes';
import Avaliacoes from './components/Avaliacoes';
import Slide from './components/slide';
import {useParams} from 'react-router-dom';
import api from '../../Services/api';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  
    

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div className="box-tables">{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper, 
  }
 
  
}));

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [imagens, setImagens] = useState([])
  const [promoValue, setPromoValue] = useState([])
  const [rateValue, setRateValue] = useState([])

  
  let params = useParams() 

  useEffect(()=>{ 

      const img = async ()=>{
        const response = await api.get(`/anuncio/galeria_fotos/${params.id}`)
        setImagens(response.data)  
    
      }

      // necessário implementar endpoint de vídeos junto com as imagens exibidas no slide do anuncio

      // const videos = async ()=>{
      //   const response = await api.get(`/anuncio/videos_anuncio/${params.id}`)
      //   setImagens(response.data)  
    
      // }

        async function loadPromo(){
        const response = await api.get(`/anuncio/promocoes/${params.id}`)
        setPromoValue(response.data)
        
    } 
    loadPromo()

      async function loadRate(){
        const response = await api.get(`/anuncio/avaliacoes/${params.id}`)
        setRateValue(response.data)
      
    } 
      loadRate()
      img()
   },[])

   

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  

  return (
    <div className={classes.root.tabs}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Informações" {...a11yProps(0)} />
          <Tab label="Foto/Video" {...a11yProps(1)} />
         {
           promoValue.length >=1 ?<Tab label="Promoções" {...a11yProps(2)} />:""
         } 

         {
           rateValue.length >=1 ?<Tab label="Avaliações" {...a11yProps(3)} /> :""

         }
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        
        <div><Informacoes/></div>
          
      </TabPanel>
      <TabPanel value={value} index={1}>
          <Slide imagem = {imagens}/>
          
      </TabPanel>
      <TabPanel value={value} index={2}>
          <Promocoes/>
      </TabPanel>
      <TabPanel value={value} index={3}>
          <Avaliacoes/>
      </TabPanel>
    
  
    </div>
  );
}