import React,{useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import api from '../../../Services/api'

export default function Avaliacoes(){

    let params = useParams()

    const [rate, SetRate] = useState([''])


    useEffect(()=>{

        async function loadRate(){
            const response = await api.get(`/anuncio/avaliacoes/${params.id}`)
            SetRate(response.data)
        } 
        loadRate()
    },[])

    return(
        
        <div>
            <div>{rate && 
                <div className="nenhum-item">
                    <h1>Este forncecedor ainda não tem avaliações</h1>
                </div>
            }</div>


        </div>
        

    )



}