import React,{useState} from "react"
import Modal from 'react-bootstrap/Modal'
import Image from "../../../../Services/img"
import "../fornecedorModal/style.css"


const FornecedorModal = ({show, setShow, fornecedores})=>{
    const handleClose = () => setShow(false);
    
      return (
        <>
  
        <Modal className="modal-Fonrnecedor" show={show} onHide={handleClose}>

          <Modal.Header closeButton>

            <Modal.Title>Fornecedores</Modal.Title>

          </Modal.Header>

                <Modal.Body>
                    
                    

                        <div className="row">
                            {
                                fornecedores.length !== 0 ?
                          
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                    <th>Foto</th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col">Cidade</th>
                                    <th scope="col">Bairro</th>
                                    <th scope="col">Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        fornecedores.map(fornecedor =>(
                                            <tr>
                                                <td><img className="img-fornecedor" src={Image+fornecedor.fotosdestaquecaminho} alt=""/> </td>
                                                <td>{fornecedor.fantasia} </td>
                                                <td>{fornecedor.descricao} </td> 
                                                <td>{fornecedor.nome} </td> 
                                                <td>{fornecedor.bairro} </td>  
                                                <td>{fornecedor.idestado} </td>
                                            
                                            </tr>
                                        ))
                                    }
                                  
                                    
                                </tbody>
                        </table>
                        :
                        <div className="fornecedor-zero">Não há fornecedores</div>
                          }

                        </div>
                

                </Modal.Body>
{/* 
          <Modal.Footer>

            <button variant="primary" className="btn btn-modal" onClick={handleClose}>
              Fechar
            </button>

          </Modal.Footer> */}

        </Modal>
      </>
      )

}

export default FornecedorModal;