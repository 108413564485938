import React, { useEffect } from 'react';
import Banner from './Banner'
import Filtro from './Filtro'
import Galeria from './Galeria'
import Footer from '../../components/Footer/Footer'
import './css/home.css'


export default function Home(history){
    useEffect(()=>{
        smoothScrool()
    })

    const smoothScrool = ()=>{
        document.querySelector("html").style="scroll-behavior: smooth;"

    }

return(
    <>
    <Banner/>
    <Filtro />
    <Galeria/>
    <Footer/>
    </>
)
}