import React from "react"
import Carousel from 'react-bootstrap/Carousel'
import Img from "../../../../Services/img"
import "./style.css"

const Slider = ({urlImage})=>(
    <Carousel>
        {
            urlImage.map(url=>(

                <Carousel.Item key={url.id}>
                    <img className="d-block w-100" src={Img+url.caminho} alt="First slide"/>
                </Carousel.Item>
            ))
        }
                
           
    </Carousel>
);

export default Slider;
