import React from "react"
import api from '../../Services/api'
import "bootstrap/dist/css/bootstrap.css"
import {faUser} from "@fortawesome/free-regular-svg-icons"
import {faLock} from "@fortawesome/free-solid-svg-icons"
import "./style.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Footer from "../../components/Footer/Footer"
import { useEffect, useState } from 'react'


const Login = ()=>{

    const [email, setEmail] = useState("");
    const [password, setPassword ] = useState("");

    const getEmail = (e)=>{
        setEmail(e.target.value) 
        
    }

    const getPassord = (e) =>{
        setPassword(e.target.value)
    }
  
  const login = async ()=>{
  
      let data ={
          email: email,
          password:password
  
      }
  
            try {
                const response = await api.post(`/consumidor/loginsubmit`,data)
                    
                window.localStorage.setItem('token', response.data.token_acess);
                window.localStorage.setItem('id', response.data.id);
                window.localStorage.setItem('anunciante_estado', response.data.estado_id);
                window.location.href = "http://lup.lionsoft.com.br/index.html";
                
            } catch (error) {
                alert(error)
            }
              
}

    return(
    <div className="container-fluid">

        <div className="row">
            <div className="col-md-12 text-center">
                <h1 className="mt-30">Login de acesso</h1>
            </div>
        </div>

        <div className="row">

               <div className="col-md-4 offset-md-4 mt-30">

                    <div className="form-group text-center login-block col-md-9 offset-md-2">

                            <label  htmlFor="usuario">Email</label>

                            <div className="user">

                                <FontAwesomeIcon className="user-icon" icon={faUser}/>
                            <input type="text" onChange={getEmail} className="form-control" id="usuario" />

                            </div>
                        
                    </div>

                    <div className="form-group text-center login-block col-md-9 offset-md-2">
                        <label htmlFor="senha">Senha</label>

                            <div className="user">
                                <FontAwesomeIcon className="user-icon" icon={faLock}/>
                                <input onChange={getPassord} type="password" className="form-control" id="senha" />
                            </div>
                        
                    </div>

                    <div class="form-group text-center col-md-9 offset-md-2">
                        <button onClick={login} className="btn btn-login">Entrar</button>
                    </div>

               </div>

        </div>
        <div className="row mt-120">
        <Footer></Footer>
        </div>
    </div>
)

}




export default Login;