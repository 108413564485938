import React,{useEffect, useState} from "react"
import Modal from 'react-bootstrap/Modal'
import api from "../../../../Services/api"
import "../modal/style.css"


const BootstrapModal = ({show, setShow, id})=>{

  const [nome, setNome] = useState("")
  const [recado, setRecado] = useState("")


  const enviaRecado = (e)=>{
     envia()
     handleClose()
     setNome("")
     setRecado("")
  }


  const envia = async ()=>{
      let data ={
        recado: recado,
        nome_remetente: nome,
        evento_id: parseInt(id)
      }


    // console.log(data)

    const response = await api.post(`/evento/recados`,data)  
    // console.log(response)
    
}
    const handleClose = () => setShow(false);
    
      return (
        <>
        <Modal show={show} onHide={handleClose}>

          <Modal.Header closeButton>

            <Modal.Title>Enviar Recado </Modal.Title>

          </Modal.Header>
                <Modal.Body>
                    
                    <div className="container">

                        <div className="row">
                         
                            <div className="col-md-12 text-center">
                               <h1 className="modal-sub-title">Recado sujeito a aprovação*</h1>    
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                        <input onChange={(e)=>{setNome(e.target.value)}}  type="text" className="form-control"   placeholder="Seu nome"/>
                                </div> 
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                     <textarea onChange={(e)=>{setRecado(e.target.value)}} className="form-control" placeholder="Escreva aqui seu recado (máx 125 caracteres)" rows="7"></textarea>
                                </div> 
                            </div>
                        </div>

                    </div>


                </Modal.Body>

          <Modal.Footer>

            <button variant="primary" disabled={nome.length> 0 && recado.length> 0  ?false :true}  className="btn btn-modal" onClick={enviaRecado}>
              Enviar
            </button>

          </Modal.Footer>

        </Modal>
      </>
      )

}

export default BootstrapModal;