import React, {useEffect , useState} from 'react';
import {useParams} from 'react-router-dom';
import api from '../../../Services/api';
import '../css/promocoes.css'


export default function Promocoes (){

    let params = useParams()

    const [promos, setPromos] = useState([]);
    const [dateInit, setDateInit] = useState([]);
    const [dateEnd, SetDateEnd] = useState([]);

    useEffect(()=>{

        async function loadPromo(){
            const response = await api.get(`/anuncio/promocoes/${params.id}`)
            setPromos(response.data) 
     
        } 
        loadPromo()
    },[])
    useEffect(()=>{

        async function loadDate(){
            
            for(let i=0; promos.length > i; i++ ){
                let diaInicio = promos[i].dia_inicio;
                diaInicio = diaInicio.split('-')
                setDateInit(`${diaInicio[2]}/${diaInicio[1]}/${diaInicio[0]}`)

                let diaFim = promos[i].dia_fim;
                diaFim = diaFim.split('-')
                SetDateEnd(`${diaFim[2]}/${diaFim[1]}/${diaFim[0]}`)

                
            }

        } 
        loadDate()
    },[promos])
    
    return(
        <div>
            <div>{!promos[0] && 
                <div className="nenhum-item">
                    <h1>Nenhuma Promoção encontrada</h1>
                </div>
            }</div>    

            {promos && <div>    
            {promos.map((promos) => (
                <div className="promocoes" key={promos.id}>
                    <h1>{promos.nome}</h1>
                    <h4>Promoção válida de: {dateInit} até {dateEnd}  </h4>
                    <p>{promos.descricao}</p>
                </div>
            ))}
            </div>
            }
            


        </div>

    )


}