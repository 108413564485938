import React,{useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import api from '../../../Services/api';
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import '../css/slide.css'

export default function Avaliacoes(props){

    let params = useParams()

    const [imagens, setImagens] = useState([])
   useEffect(()=>{ 
    
        setImagens(props.imagem)
        // console.log("teste2",props.imagem)
    },[])

    
    useEffect(()=>{

        var track = document.querySelector('.slide-track');
        
        var slides = Array.from(track.children)

        let slideWidth = track.getBoundingClientRect().width;
        
       
       if(slides[0]){
            slides[0].classList.add('current-slide')
       }

       slides.forEach((slide, index) => {

            slide.style.left = slideWidth * index + 'px'

       });

       
      
              
    },[imagens])

    function handleNext(){
        var track = document.querySelector('.slide-track');
        const currentSlide = track.querySelector('.current-slide');
        const nextSlide = currentSlide.nextElementSibling;
        
    
        if(nextSlide){
            moveToSlide(track, currentSlide, nextSlide)
        }
    }   
    function handlePrev(){
        var track = document.querySelector('.slide-track');
        const currentSlide = track.querySelector('.current-slide');
        const prevSlide = currentSlide.previousElementSibling;
        
        if(prevSlide){
            moveToSlide(track, currentSlide, prevSlide)
        }    
    }
    function handleThumb(e){
        var track = document.querySelector('.slide-track');
        var slides = Array.from(track.children)
        const currentSlide = track.querySelector('.current-slide');
        const trackThumb = document.querySelector('.thumb');
        const thumb = e.target.src   
        var  targetSlide
        // console.log(e.target)
          
        slides.forEach((slide, index) => {
            var thumbSlide = Array.from(slide.children)
            const slideSrc = thumbSlide[0].src
            if (slideSrc == thumb){
                targetSlide = slides[index]
                e.target.classList.add('current-thumb')
            }
     
        });
        
        moveToSlide(track, currentSlide, targetSlide)
        
        

    }

    const moveToSlide = (track, currentSlide, targetSlide) => {

        track.style.transform = `translateX(-${targetSlide.style.left})`;
        currentSlide.classList.remove('current-slide');
        targetSlide.classList.add('current-slide');


    }
 
    return(
        <div>
        <div className="slide">
            
            <button className="btn-slide-left" onClick={handlePrev} ><IoIosArrowBack size="50"/></button>
            <div className="slide-container">
                <ul className="slide-track">
                {imagens.map(imagem => (
                    
                        <li className="slide-img" key={imagem.id}>
                            <img src={`https://api.lup.com.br/storage/${imagem.caminho}`} />
                        </li>
                        
                ))}
                
                    {/* {console.log("teste",imagens)} */}
                </ul>
                 
                
            </div>
                     
           
            <button className="btn-slide-right" onClick={handleNext} ><IoIosArrowForward  size="50" /></button>
              
           
                    
        </div>
        <ul className="thumb">
                {imagens.map(imagem => (
                    
                        <li onClick={handleThumb} key={imagem.id}>
                            <img src={`https://api.lup.com.br/storage/${imagem.caminho}`} />
                        </li>
                        
                ))}
            </ul> 
        </div>

        

    )



}
 
