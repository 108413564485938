import React from "react"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import "./style.css"


const ModalAlert = ({title, body, open, close, btnMessage})=>{


    return (
    <>
      
        <Modal show={open} onHide={close}>
            <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">{body}</Modal.Body>
            <Modal.Footer>
        
            <Button className="btnModal" variant="primary" onClick={close}>
                {btnMessage}
            </Button>
            </Modal.Footer>
        </Modal>
      </>
      )
}

export default ModalAlert;