import React, {useEffect, useState} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import api from "../../../../Services/api"
import "./style.css"

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));




export default function LimitTags() {
  const classes = useStyles();

  const [culinaria, setCulinaria] = useState([])


  useEffect(()=>{
    loadCulinaria()
},[])


  const loadCulinaria = async ()=>{
    const response = await api.get("/opcoesculinarias")
    setCulinaria(response.data)
    console.log(response.data)
}



  return (
    <div className="culinaria-chip">
      <Autocomplete
        multiple
        id="tags-culinaria"
        options={culinaria}
        getOptionLabel={(option) => option.descricao}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
           
          />
        )}
      />
      </div>
  );
}
