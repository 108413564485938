import React, {useEffect, useState} from 'react';
import './css/Filtro.css'
import casados from '../../Assets/casados.png'
import aniversario from "../../Assets/aniversario.png"
import corporativo from "../../Assets/corporativo.png"
import happy from "../../Assets/happy.png"


import api from '../../Services/api'
import { useHistory } from "react-router-dom"

export default function Filtro(){
    let history = useHistory();

    const [estados, setEstados] = useState([]);
    const [estadoEscolhido, setEstadoEscolhido] = useState('');
    const [cidades, setCidades] = useState([]);
    const [escolhaCidade, setEscolhaCidade] = useState('')
    const [tipos, setTipos] = useState([]);
    const [ramo, setRamo] = useState([]);
    // const [escolhaTipos, setEscolhaTipos] = useState(4);
    const [escolhaTipos, setEscolhaTipos] = useState('');
    const [temas, setTemas] = useState([]);
    const [escolhaTema, setEscolhaTema] = useState([])
    const [imgFilter, setImgFilter] = useState(casados)
    const [selectTipo, setSelectTipo] = useState()
    //const [selectTipo, setSelectTipo] = useState(4)
    


    useEffect(()=>{
        async function loadEstados(){
            const response = await api.get('/estados');
            
            setEstados(response.data);
        }

        loadEstados();

        async function loadTipos(){
            const response = await api.get('/tiposeventos');
            // console.log("tipo evento" , response)
            setTipos(response.data);
           
        }

        loadTipos();

        async function loadRamo(){
            const response = await api.get("/ramo")
            // console.log(response.data)
            setRamo(response.data);
        }

        loadRamo();


         async function loadTemas(){
            const response = await api.get('/temaestabelecimento');

            setTemas(response.data);
        }
        loadTemas();
        

    },[])

    function handleSelect(e){
        let id 
        setEscolhaCidade('')
        setEstadoEscolhido(e.target.value)
        // console.log(e.target.value)
        
        id =  e.target.value

        async function loadCidades(){
            const response = await api.get(`/cidades/${id}`);
            
            setCidades(response.data);
            
        }
        
        loadCidades();

    }
    function filtrar(tipo){

        let dados

        if(tipo){
            console.log(tipo)
         dados = `${estadoEscolhido}&${escolhaCidade}&${tipo}&${escolhaTema}`

       }else{
        // console.log('é o escolha tipo',escolhaTipos)
         dados = `${estadoEscolhido}&${escolhaCidade}&${escolhaTipos}&${escolhaTema}`

       }

        // console.log(dados)
        history.push(`/filter/${dados}`)
        
    }

    const resetFilter = (event) =>{
        setEscolhaTipos(event.target.value)
        setSelectTipo()
        
    }
   

return(

    <section id="filtro">
        <div className="container-filtro">
            <div className="title-filtro">
                <h1>NO LUP O SEU EVENTO É COMPLETO</h1>
                <h2>Monte, organize e convide. Tudo em um lugar só.</h2>
            </div>
            <div className="body-filtro">
                <div className="type-filter">
                {/* type-select-filter filter-select */}
                <ul> 
                    <li className={imgFilter == casados?"type-select-filter filter-select":"filter-select"}  onMouseOver={()=>{ setImgFilter(casados); }  } onClick={()=>{filtrar(4)}}><h1>Casamento</h1></li>
                    <li className={imgFilter == aniversario?"type-select-filter filter-select":"filter-select"}onClick={()=>{filtrar(1)}}><h1  onMouseOver={()=>{ setImgFilter(aniversario);} }>ANIVERSÁRIO</h1></li>
                    <li className={imgFilter == corporativo?"type-select-filter filter-select":"filter-select"} onClick={()=>{filtrar(22)}}><h1 onMouseOver={()=>{ setImgFilter(corporativo);} }>CORPORATIVO</h1></li>
                    <li className={imgFilter == happy?"type-select-filter filter-select":"filter-select"} onClick={()=>{filtrar(10)}}><h1 onMouseOver={()=>{setImgFilter(happy);} }>HAPPY HOUR</h1></li>

                    {/* <li className={imgFilter == casados?"type-select-filter filter-select":"filter-select"} onClick={filtrar} onMouseOver={()=>{ setImgFilter(casados); setSelectTipo("4");setEscolhaTipos(4)}  } ><h1>Casamento</h1></li>
                    <li className={imgFilter == aniversario?"type-select-filter filter-select":"filter-select"} onClick={filtrar}><h1  onMouseOver={()=>{ setImgFilter(aniversario); setSelectTipo("1");setEscolhaTipos(1) } }>ANIVERSÁRIO</h1></li>
                    <li className={imgFilter == corporativo?"type-select-filter filter-select":"filter-select"} onClick={filtrar}><h1 onMouseOver={()=>{ setImgFilter(corporativo); setSelectTipo("22");setEscolhaTipos(22) } }>CORPORATIVO</h1></li>
                    <li className={imgFilter == happy?"type-select-filter filter-select":"filter-select"} onClick={filtrar}><h1 onMouseOver={()=>{setImgFilter(happy); setSelectTipo("10"); setEscolhaTipos(10) } }>HAPPY HOUR</h1></li> */}
                </ul>
                </div>
                <div className="img-filter">
                    <img src={imgFilter} alt="imagem"/>
                </div>
                <div className="form-filter">
                    <select onChange={handleSelect}>
                    <option value="estado">Selecione o Estado</option>
                        {estados.map(estado => (
                        <option value={estado.id} key={estado.id}>
                            {estado.nome}
                        </option>
                        ))}
                    </select>

                    <select onChange={e => setEscolhaCidade(e.target.value)}>                    
                        <option>Selecione a cidade</option>
                        {cidades.map(cidade => (
                            <option value={cidade.id} key={cidade.id} >
                                {cidade.nome}
                            </option>
                        ))}
                    </select>

                    <select onChange={event => resetFilter(event) } value={selectTipo}>
                    <option value="">Tipo do evento</option>
                        {tipos.map(tipo => (
                            <option value={tipo.id} key={tipo.id}>{tipo.descricao}</option>
                            
                        ))} 
                      
                    </select>

                    <select onChange={event => resetFilter(event) }>
                    <option>Tipo do serviço</option>
                        {ramo.map(ramo => (
                            <option value={ramo.id} key={ramo.id}>{ramo.descricao}</option>
                            
                        ))} 
                      
                    </select>
                    <select onChange={e => setEscolhaTema(e.target.value)}>
                        <option value="tema">Tema/Estilo</option>
                        {temas.map(tema =>(
                            <option value={tema.id} key={tema.id}>
                                {tema.descricao}
                            </option>
                        ))}
                    </select>
                    <button onClick={()=>{filtrar()}}>Filtrar Fornecedores</button>
                </div>

            </div>
        </div>


    </section>
    
)
}