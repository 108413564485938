import React, {useEffect, useState} from 'react';
import Modal from "./components/modal/index"
import "bootstrap/dist/css/bootstrap.css"
import "./style.css"
import api from '../../Services/api';
import Footer from "../../components/Footer/Footer"

const Convite = () =>{
    
    const [email, setEmail] = useState("");
    const [qtdConvite, setQtdconvite] = useState (-1);
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);


   async function checarConvite(e){

           e.preventDefault()
           const response = await api.get(`./evento/consultar/email/${email}`)
           setQtdconvite(response.data.eventos_convite)
           
    }


    const enviarEmail = async ()=>{
        const response = await api.get(`./evento/enviar/email/${btoa(email)}`)
        setQtdconvite(-1)
        setEmail("")
   
    }



    const handleEnviar = ()=>{

        handleShow()
        enviarEmail()

     
    }

    return(

        <div className="container-fluid">

            <div className="row">
                <div className="col-md-6 offset-md-3 mt-30">
                    <h1>Pesquisar convite para evento</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-md-8 offset-md-3">
                    <div className="form-group col-md-8 col-lg-4 mt-30 p-0">
                       <form onSubmit={checarConvite}>
                            <label  htmlFor="usuario">E-mail</label>

                            <div className=" convite">

                                <input type="email" required className="form-control" value={email} id="convite" onChange={(e)=> setEmail(e.target.value)} />

                                <button type="submit"  className="btn">Pesquisar</button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>

                <div className="row">
                    <div className="col-md-6 offset-md-3">
                    {
                        qtdConvite > 0 ?
                        <>
                        <p className="convite-sim">Você tem {qtdConvite} convites para eventos , deseja receber os convites em seu email? </p>
                        <button onClick={handleEnviar} className="btn btn-evniar">Enviar</button>
                        </> :
                        qtdConvite ==0 ? 
                        
                        <p className="convite-nao">Não há convites pendentes</p> :
                        ""
                    }

                
                    <Modal  show={show} setShow={setShow}/>
                    </div>
                </div>

                <div className="row mt-120">
                    <Footer/>
                </div>
        </div>
        
      
    )


}

export default Convite;